<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/60 data-[state=open]:animate-overlay-show"
    >
      <DialogContent
        as-child
        :aria-describedby="undefined"
        @pointer-down-outside="
          onPointerDownOutside($event.detail.originalEvent)
        "
      >
        <Box
          rounded="lg"
          class="w-[90vw] max-w-[570px] bg-white focus:outline-none data-[state=open]:animate-content-show"
        >
          <div class="flex items-start justify-between gap-2 p-5">
            <DialogTitle
              class="font-Kontesa text-[28px] font-semibold leading-[33px]"
            >
              Change avatar
            </DialogTitle>
            <Box class="h-8 w-8 shrink-0 bg-white" rounded="full">
              <DialogClose
                class="flex h-full w-full items-center justify-center"
              >
                <IconsClose class="h-3.5 w-3.5 stroke-[4]" />
              </DialogClose>
            </Box>
          </div>

          <div class="-m-px rounded-xl border border-navy bg-pink-low p-5">
            <div
              v-if="!url"
              class="relative h-44 rounded-xl border-2 border-dashed border-navy/60 transition-colors hover:border-navy md:h-72"
            >
              <input
                class="h-full w-full cursor-pointer bg-transparent text-transparent file:sr-only"
                type="file"
                accept="image/*"
                @input="onFileChange"
              />
              <div
                class="pointer-events-none absolute top-0 flex h-full w-full flex-col items-center justify-center"
              >
                <IconsImageUp class="h-8 w-8 text-navy/80" size="32" />
                <span class="text-xl font-bold text-navy/80">
                  Drag and drop an image
                </span>
                <span class="text-navy/70">or click</span>
              </div>
            </div>
            <div v-else>
              <img
                ref="imgRef"
                class="block h-44 w-full max-w-full md:h-72"
                :src="url"
              />
            </div>

            <Separator
              orientation="horizontal"
              class="my-4 border-b border-dashed border-navy"
            />

            <div class="flex items-center justify-between gap-2">
              <Button :disabled="!url || isPending" @click="url = null">
                Clear
              </Button>
              <Button
                class="w-32"
                variant="primary-flat"
                :disabled="!url || isPending"
                @click="upload"
              >
                <template v-if="!isPending">Upload</template>
                <svg
                  v-else
                  class="inline-block h-[16px] w-[16px] animate-spin fill-gray-600 text-gray-200"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </Box>
      </DialogContent>
    </DialogOverlay>
  </DialogPortal>
</template>

<script setup lang="ts">
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { toast } from "vue3-toastify";
import { memberQueries } from "~/domains/member/queries";

const emit = defineEmits<{ (e: "success"): void }>();

function onPointerDownOutside(event: PointerEvent) {
  const target = event.target as HTMLElement;
  if (
    event.offsetX > target.clientWidth ||
    event.offsetY > target.clientHeight
  ) {
    event.preventDefault();
  }
}

const imgRef = ref<HTMLImageElement | null>(null);
let cropper: Cropper | null;
watchEffect(() => {
  if (imgRef.value) {
    cropper = new Cropper(imgRef.value, {
      aspectRatio: 1,
      autoCropArea: 1,
    });
  } else {
    cropper?.destroy();
    cropper = null;
  }
});

const url = ref<string | null>(null);

function onFileChange(event: Event) {
  const target = event.currentTarget as HTMLInputElement;
  const file = target.files?.[0];
  if (!file) return;
  url.value = URL.createObjectURL(file);
}

function getBlob() {
  return new Promise<Blob | null>((resolve, reject) => {
    if (!cropper) return reject("Cropper is not initialized");
    const canvas = cropper.getCroppedCanvas();
    canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
  });
}

const queryClient = useQueryClient();

const { mutate, isPending } = useMutation({
  mutationFn: async (params: { memberId: number; blob: Blob }) => {
    const api = useApi();

    const blob = await getBlob();
    if (!blob) throw new Error("Error preprocessing image");

    const formData = new FormData();
    formData.append("avatar", blob);

    const response = await api<{ id: number; avatar_path: string | null }>(
      `/v1/members/${params.memberId}/avatar`,
      {
        method: "POST",
        body: formData,
      },
    );

    return camelize(response, { deep: true });
  },
  onSuccess: () => {
    return Promise.all([
      queryClient.invalidateQueries(memberQueries.self()),
      queryClient.invalidateQueries(
        memberQueries.detail(
          queryClient.getQueryData(memberQueries.self().queryKey)?.id ?? 0,
        ),
      ),
      emit("success"),
    ]);
  },
  onError(error) {
    toast.error(error.message);
  },
});

async function upload() {
  const memberId = queryClient.getQueryData(memberQueries.self().queryKey)?.id;
  if (!memberId) {
    toast.error("You're not logged in");
    return;
  }
  let blob: Blob | null;
  try {
    blob = await getBlob();
    if (!blob) throw new Error("Error preprocessing image");
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else throw error;
    return;
  }

  mutate({ memberId, blob });
}
</script>

<style scoped>
:deep(.cropper-view-box) {
  border-radius: 50%;
}
</style>
