<template>
  <Box
    :style="{ '--partial-progress': `${(progress % 10) * 10}%` }"
    class="relative flex h-9 gap-0.5 bg-white p-1"
  >
    <div
      v-for="i in 10"
      :key="i"
      class="flex h-full flex-1 items-center justify-center"
      :class="[
        i <= progress / 10
          ? 'bg-success'
          : progress - (i - 1) * 10 > 0
            ? 'gradient'
            : 'bg-[#F1EBFF]',
        { 'rounded-l-[4px]': i === 1, 'rounded-r-[4px]': i === 10 },
      ]"
    >
      <slot name="icon" />
    </div>
    <div
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-navy"
    >
      <span
        class="inline-block font-Kontesa text-[21px] font-bold leading-[19px] tracking-tight"
        :class="{ 'level-up': leveledUp }"
      >
        LEVEL {{ level }}
      </span>
    </div>
  </Box>
</template>

<script setup lang="ts">
const props = defineProps<{ progress: number; level: number }>();
const progress = toRef(props, "progress");

const leveledUp = ref(false);
watch(progress, (value) => {
  if (value === 100) leveledUp.value = true;
});
</script>

<style scoped>
.gradient {
  background: linear-gradient(
    90deg,
    rgb(var(--color-success)) 0%,
    rgb(var(--color-success)) var(--partial-progress),
    #f1ebff var(--partial-progress),
    #f1ebff 100%
  );
}

.level-up {
  animation: grow-shrink 600ms;
}

@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
</style>
