<template>
  <div class="relative aspect-square">
    <svg
      class="h-full w-full rotate-2 transform stroke-navy"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="100"
        cy="100"
        r="85"
        fill="transparent"
        stroke-width="20"
        stroke-dasharray="60, 6.758"
      />
    </svg>

    <svg
      class="absolute top-0 h-full w-full stroke-navy"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="transparent"
        stroke-width="20"
        stroke-dasharray="64, 2"
        :d="dShadow"
      />
      <svg
        class="absolute top-0 h-full w-full stroke-success"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="transparent"
          stroke-width="17"
          stroke-dasharray="60, 6"
          :d="dFill"
        />
      </svg>
    </svg>

    <Box
      class="group absolute left-1/2 top-1/2 h-[63%] w-[63%] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-gradient-to-tr from-pink-low to-ocean"
      border="lg"
      rounded="full"
      shadow="lg"
    >
      <DialogRoot v-model:open="isChangeAvatarModalOpen">
        <DialogTrigger
          :as="isViewer ? undefined : 'template'"
          :as-child="isViewer"
        >
          <component
            :is="isViewer ? 'button' : 'div'"
            class="relative h-full w-full"
            :type="isViewer ? 'button' : undefined"
            :class="isViewer ? 'avatar-change-button' : undefined"
          >
            <img
              v-if="src"
              class="h-full w-full rounded-full object-cover"
              :src="src"
              alt="Profile picture"
            />
            <div v-else class="relative h-full w-full">
              <div class="relative h-full w-full overflow-hidden rounded-full">
                <img class="absolute bottom-0 w-1/2" src="/images/cloud.png" />
                <img
                  class="absolute right-0 top-0 w-1/2"
                  src="/images/cloud.png"
                />
              </div>
              <img
                src="/images/emojis/wing.png"
                class="absolute left-1/2 top-1/2 w-2/3 -translate-x-[90%] -translate-y-[75%] -scale-x-100"
              />
              <img
                src="/images/emojis/wing.png"
                class="absolute left-1/2 top-1/2 w-2/3 -translate-x-[10%] -translate-y-[75%]"
              />
              <img
                src="/images/emojis/pig-face.png"
                class="absolute left-1/2 top-1/2 w-2/3 -translate-x-1/2 -translate-y-[40%]"
              />
            </div>
          </component>
        </DialogTrigger>
        <ProfileAvatarChangeModalContent
          v-if="isViewer"
          @success="
            isChangeAvatarModalOpen = false;
            toast.success('Avatar updated');
          "
        />
      </DialogRoot>
    </Box>

    <Box
      class="absolute bottom-0 left-1/2 flex aspect-[1.5] h-1/6 min-h-[52px] -translate-x-1/2 transform flex-col items-center justify-center bg-success px-3 py-2.5"
    >
      <div class="text-[14px] leading-[18px]">Level</div>
      <div
        class="font-Kontesa text-[28px] font-bold leading-none tracking-tight"
        :class="{ 'level-up': leveledUp }"
      >
        {{ level }}
      </div>
    </Box>
  </div>
</template>

<script setup lang="ts">
import { toast } from "vue3-toastify";

const props = defineProps<{
  src?: string;
  progress: number;
  level: number;
  isViewer?: boolean;
}>();

const progress = toRef(props, "progress");

const dFill = computed(() => generatePath(progress.value, 84, 1.4));
const dShadow = computed(() => generatePath(progress.value, 84));

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

function generatePath(progress: number, radius: number, startAngleOffset = 0) {
  const startAngle = -90 + startAngleOffset;
  const endAngle = (clamp(progress, 0, 100) / 100) * 359.9999 + startAngle;
  const x1 = 100 + radius * Math.cos((startAngle * Math.PI) / 180);
  const y1 = 100 + radius * Math.sin((startAngle * Math.PI) / 180);
  const x2 = 100 + radius * Math.cos((endAngle * Math.PI) / 180);
  const y2 = 100 + radius * Math.sin((endAngle * Math.PI) / 180);
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  return `M ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`;
}

const leveledUp = ref(false);
watch(progress, () => {
  if (progress.value === 100) leveledUp.value = true;
});

const isChangeAvatarModalOpen = ref(false);
</script>

<style scoped>
.level-up {
  animation: grow-shrink 600ms;
}

.avatar-change-button {
  cursor:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewport='0 0 100 100' style='fill:black;font-size:24px;transform:scaleX(-1)'><text y='50%'>✏️</text></svg>")
      0 28,
    auto;
}

@keyframes grow-shrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
}
</style>
